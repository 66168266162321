<!-- @format -->

<template>
	<div>
		<div style="color: #606060">
			<div class="line-head">
				Selected Dispatch: {{ selectedDispatchNumber }}
			</div>
			<div class="line-head">Dispatch Date: {{ selectedDispatchDate }}</div>
			<div class="line-head">Customer: {{ selectedDispatchCustomer }}</div>
			<div>
				<vue-good-table
					id="selected_dispatch"
					:columns="columnsSelectedDispatch"
					:rows="rowsSelectedDispatch"
					styleClass="vgt-table striped bordered"
					theme="black-rhino"
					:fixed-header="true"
					@on-row-dblclick="onRowDoubleClick"
					@on-cell-click="onCellClick"
					:search-options="{
						enabled: false,
					}"
					:pagination-options="{
						enabled: false,
						mode: 'records',
						perPage: 100,
						position: 'bottom',
						perPageDropdown: [5, 10, 15, 20, 100],
						dropdownAllowAll: false,
						setCurrentPage: 1,
						jumpFirstOrLast: true,
						firstLabel: 'First',
						lastLabel: 'Last',
						nextLabel: 'next',
						prevLabel: 'prev',
						rowsPerPageLabel: 'Rows per page',
						ofLabel: 'of',
						pageLabel: 'page', // for 'pages' mode
						allLabel: 'All',
					}"
				>
					<template slot="table-row" slot-scope="props">
						<span v-if="props.column.field === 'invoice_price'">
							<p class="text-bold text-green text-align-right">
								{{ numeral(props.row.invoice_price / 100).format('0,0.00') }}
							</p>
						</span>
						<span v-else-if="props.column.field === 'dispatch_amount'">
							<p class="text-bold text-purple text-align-center">
								{{ props.row.dispatch_amount }}
							</p>
						</span>
						<span v-else-if="props.column.field === 'vat_credit'">
							<p class="text-bold text-blue text-align-right">
								{{ numeral(props.row.vat_credit / 100).format('0,0.00') }}
							</p>
						</span>
						<span v-else-if="props.column.field === 'line_price_total_credit'">
							<p class="text-bold text-align-right">
								{{
									numeral(props.row.line_price_total_credit / 100).format(
										'0,0.00',
									)
								}}
							</p>
						</span>
						<span v-else>
							{{ props.formattedRow[props.column.field] }}
						</span>
					</template>
				</vue-good-table>
				<div class="line-foot">
					<span></span>
					Lines Total:
					<span style="margin-left: 20px">{{
						numeral(linesTotal / 100).format('0,0.00')
					}}</span>
				</div>
				<div class="line-foot">
					Vats Total:
					<span style="margin-left: 20px">{{
						numeral(vatsTotal / 100).format('0,0.00')
					}}</span>
				</div>
				<div class="line-foot">
					Order Total:
					<span style="margin-left: 20px">{{
						numeral(orderTotal / 100).format('0,0.00')
					}}</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import numeral from 'numeral'
import useSelectedDispatchViewState from '@/_srcv2/pages/sale-invoice/_shared/useSelectedDispatchViewState.js'
import { computed, onMounted } from '@vue/composition-api'
export default {
	name: 'ViewSelectedDispatch',
	setup() {
		const {
			rowsSelectedDispatch,
			columnsSelectedDispatch,
			selectedDispatchNumber,
			selectedDispatchDate,
			selectedDispatchCustomer,
		} = useSelectedDispatchViewState()
		onMounted(() => {
			console.log('rowsSelectedDispatch', rowsSelectedDispatch.value)
			console.log('columnsSelectedDispatch', columnsSelectedDispatch.value)
			console.log('selectedDispatchNumber', selectedDispatchNumber.value)
			console.log('selectedDispatchDate', selectedDispatchDate.value)
			console.log('selectedDispatchCustomer', selectedDispatchCustomer.value)
		})
		const onCellClick = () => {
			return
		}
		const onRowDoubleClick = () => {
			return
		}
		// * -----------------------------------------------------
		const linesTotal = rowsSelectedDispatch.value.reduce(function (
			accumulator,
			item,
		) {
			return accumulator + item.line_price_total_credit
		},
		0)
		const vatsTotal = rowsSelectedDispatch.value.reduce(function (
			accumulator,
			item,
		) {
			return accumulator + item.vat_credit
		},
		0)
		const orderTotal = computed(() => linesTotal + vatsTotal)
		return {
			columnsSelectedDispatch,
			rowsSelectedDispatch,
			selectedDispatchNumber,
			selectedDispatchDate,
			selectedDispatchCustomer,
			onCellClick,
			onRowDoubleClick,
			numeral,
			linesTotal,
			vatsTotal,
			orderTotal,
		}
	},
}
</script>

<style scoped>
.line-head {
	margin-top: 8px;
	margin-bottom: 7px;
	font-weight: bold;
}
.line-foot {
	margin-top: 8px;
	margin-bottom: 7px;
	text-align: right;
	padding-right: 10px;
	font-weight: bold;
}
</style>
